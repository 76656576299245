<template>
	<div class="onas">
		<h1>O nas</h1>



<p>
W chwili obecnej w realizację projektu zaangażowanych jest dwoje
pracowników Muzeum im. ks. dr. Władysława Łęgi w Grudziądzu:
Emilia Markot-Borkowska (pomysłodawczyni i główny koordynator
projektu) oraz Mateusz Promiński. Ze względu na szeroki zakres
tematyki autorzy nie wykluczają poszerzenia grona redaktorów
portalu.
</p>

<p><b>Emilia Markot-Borkowska</b> jest absolwentką archeologii i historii sztuki
na Wydziale Historycznym Uniwersytetu Mikołaja Kopernika w Toruniu.
<img loading="lazy" decoding="async" alt="Mateusz Promiński, Emilia Markot-Borkowska" style="float:right; width: 250px; margin: 20px;" src="../assets/onas.jpg">Ukończyła również podyplomowe studia z zakresu muzealnictwa na
Uniwersytecie Jagiellońskim w Krakowie. W grudziądzkim Muzeum
pracuje od 2008 r., zaś od 2010 r. piastuje stanowisko kierownika
działu sztuki. Obecnie pełni również funkcję członka zarządu
Oddziału Kujawsko-Pomorskiego Stowarzyszenia Muzealników Polskich
oraz Grudziądzkiego Towarzystwa Kultury. Jest autorką wielu
ekspozycji muzealnych, katalogów wystaw oraz artykułów
w&nbsp;publikacjach naukowych. W sferze jej zainteresowań znajduje
się przede wszystkim współczesne malarstwo pomorskie oraz
zagadnienia związane ze sztuką lokalną.</p>

<p><b>Mateusz Promiński</b> – absolwent filologii polskiej o specjalizacji
folklorystycznej oraz historii sztuki na Uniwersytecie Mikołaja
Kopernika w Toruniu. W 2017 roku zatrudniony jako asystent działu
sztuki w&nbsp;Muzeum im. ks. dr. Władysława Łęgi w Grudziądzu,
od 2019 r. pracuje na stanowisku adiunkta działu sztuki. Autor
wystaw, katalogów oraz działań edukacyjnych głównie z&nbsp;zakresu
sztuki współczesnej. Publikuje w mediach branżowych
i&nbsp;opracowaniach naukowych. Interesuje się recepcją tradycji
romantycznej, współczesnym malarstwem polskim, wzornictwem oraz
kulturą tradycyjną. Grudziądzanin z wyboru, zakochany w ziemi
chełmińskiej. 
</p>





	</div>
</template>

<script>

// console.log('licomponent', licomponent);

export default {
	name: 'onas',


	data() {
		return {
			loading: true,
			error: false,
			msg: 'Welcome to my Vuex Store',
		};
	},

	computed: {

	},
	mounted() {


	},


};
</script>

<style lang="less">
</style>
