<template>
	<div class="projekt">
		<h1>Projekt</h1>
<p>
<i><b>Grudziądzka strefa kultury</b></i>&nbsp;to prezentowana w
formie witryny internetowej baza osób, instytucji i organizacji,
mających wpływ na życie kulturalne Grudziądza na przestrzeni
wieków. Zbiór obejmuje zarówno inicjatorów i uczestników
działalności oświatowo-artystycznej dawnego Grudziądza, jak
i&nbsp;współcześnie żyjących twórców. W chwili obecnej baza zawiera kilkadziesiąt haseł. Rozbudowa <i>strefy</i> będzie
kontynuowana w kolejnych miesiącach 2021 roku. Docelowo planowane
jest stworzenie kilkuset haseł, przybliżających szeroko rozumianą
działalność kulturalną w naszym mieście. W tym też celu
przygotowano ankietę, która systematycznie rozsyłana do czynnych
twórców, pozwala pozyskać istotne informacje o ich działalności.
Równocześnie trwają prace związane z opracowywaniem biogramów
nieżyjących już osób, oraz redagowaniem artykułów dotyczących
dawnych organizacji i instytucji, które funkcjonowały w naszym
mieście w poprzednich stuleciach. Informator zbudowany został
w&nbsp;oparciu o&nbsp;tematyczne hasła, podzielone na cztery
kategorie:</p>
<br>
<p>
LUDZIE
</p>
<p>
W kategorii tej znajdują się biogramy grudziądzkich twórców:
plastyków, muzyków, literatów, aktorów, fotografików,
architektów, muzealników, konserwatorów, animatorów
i&nbsp;organizatorów działalności kulturalnej. Poszczególnym
biogramom towarzyszą galerie zdjęć, ilustrujące twórczość
opisywanych sylwetek.</p>
<br>
<p>
ZESPOŁY I GRUPY</p>
<p>
W obrębie drugiej kategorii znajdują się chóry, orkiestry i
zespoły muzyczne, pracownie plastyczne, stowarzyszenia oraz fundacje
działające na rzecz kultury.</p>
<br>
<p>
MIEJSCA</p>
<p>
W trzeciej kategorii umieszczono wykaz najważniejszych zabytków
Grudziądza, oddziaływujących na życie kulturalne miasta, lecz
także strefy, w których ogniskowała się działalność
artystyczna naszego miasta: galerie, szkoły czy kawiarnie
artystyczne. Znajdują się tu także instytucje będące od lat
głównymi filarami lokalnej kultury.</p>
<br>
<p>
MEDIA</p>
<p>Nie
mogliśmy również pominąć mediów, które od zawsze działały na
rzecz krzewienia i&nbsp;rozwoju kultury. W ostatniej kategorii
przedstawiamy gazety, czasopisma czy programy telewizyjne,
przybliżające informacje o wydarzeniach kulturalnych.</p>
<br>
<p>Projekt
<i>Grudziądzka strefa kultury</i> sfinansowano ze środków Gminy
Miasta Grudziądz.</p>





	</div>
</template>

<script>

// console.log('licomponent', licomponent);

export default {
	name: 'projekt',


	data() {
		return {
			loading: true,
			error: false,
			msg: 'Welcome to my Vuex Store',
		};
	},

	computed: {

	},
	mounted() {


	},


};
</script>

<style lang="less">
</style>
