<template>
	<div class="policy">
		<h1>Polityka prywatności</h1>


<h2>Jakie dane zbiera serwis Grudziądzka Strefa Kultury?</h2>
<p>Serwis pozwala na utrzymanie anonimowości użytkownika podczas korzystania z serwisu. Zbiera dane zawarte w logach systemowych (np. adres IP) w celach technicznych (związanych z administracją serwerami) oraz w celu zbierania ogólnych danych demograficznych (np. region, z którego IP łączy się z serwisem).</p>
<h2>Jaka jest polityka serwisu Grudziądzka Strefa Kultury dotycząca plików „cookie”?</h2>
<p>Twoja przeglądarka internetowa może przechowywać pliki tekstowe (ang. „cookies”) na dysku twojego komputera. W plikach „cookies” znajdują się informacje niezbędne do prawidłowego funkcjonowania serwisów internetowych, w szczególności tych wymagających autoryzacji.</p>
<p>Za pomocą plików cookies nie są przetwarzane lub przechowywane dane osobowe.</p>
<p>Serwis Grudziądzka Strefa Kultury przechowuje pliki cookies na komputerach użytkowników w celu tworzenia statystyk oglądalności serwisu.</p>
<p>Istnieje możliwość takiego skonfigurowania Twojej przeglądarki internetowej, która spowoduje, że całkowicie zostanie wyłączona możliwość przechowywania plików cookies na dysku twardym Twojego komputera.</p>
<h2>W jaki sposób chronione są informacje?</h2>
<p>Informacje przechowywane są i przetwarzane przez serwis Grudziądzka Strefa Kultury z zachowaniem odpowiednich środków bezpieczeństwa, spełniających wymagania polskiego prawa.</p>
<h2>Czy podane informacje osobiste są udostępniane innym podmiotom?</h2>
<p>Serwis Grudziądzka Strefa Kultury na podstawie uzyskanych informacji sporządza zbiorcze zestawienia statystyczne, które są ujawniane osobom trzecim. Zestawienia takie nie zawierają danych pozwalających na identyfikację pojedynczych użytkowników.</p>
<h2>Prawo wyboru</h2>
<p>Serwis Grudziądzka Strefa Kultury daje swoim użytkownikom możliwość wyboru, czy i w jakim zakresie chcą korzystać z naszych usług i udostępniać o sobie informacje.</p>
<h2>Słowniczek</h2>
<p>Poniższe opisy nie mają charakteru ścisłych definicji. Ich celem jest jedynie przybliżenie niektórych pojęć użytych w tym dokumencie.</p>
	<ul>
    <li><i>cookie</i> - jest to niewielka informacja zapisywana przez serwer na komputerze użytkownika w pliku cookie.txt, którą serwer może odczytać przy ponownym połączeniu się z tego komputera</li>
    <li><i>log systemowy</i> - jest to informacja, jaką przekazuje serwerowi komputer użytkownika przy każdym połączeniu, może zawierać różne dane (np. numer IP), z których można oszacować skąd nastąpiło połączenie</li>
    <li><i>adres IP</i> - indywidualny numer, który z reguły posiada każdy komputer podłączony do Internetu, numer IP może być na stałe związany z danym komputerem (statyczny) lub przydzielony mu na dane połączenie (dynamiczny)</li>
	</ul>
	</div>
</template>

<script>

// console.log('licomponent', licomponent);

export default {
	name: 'policy',


	data() {
		return {
			loading: true,
			error: false,
			msg: 'Welcome to my Vuex Store',
		};
	},

	computed: {

	},
	mounted() {


	},


};
</script>

<style lang="less">
</style>
