<template>
	<div class="dolacz">
		<h1>Dołącz</h1>

<p>W chwili obecnej autorzy portalu nie przewidują możliwości redagowania artykułów przez osoby postronne. Jeśli jednak chcieliby Państwo aby Wasze dokonania pojawiły się na stronie projektu lub uważacie, iż warto dodać do jednej z czterech kategorii jakieś nowe hasła prosimy o kontakt na email: <a href="mailto:sztuka@muzeum.grudziadz.pl">sztuka@muzeum.grudziadz.pl</a></p>

<p>W ramach poszczególnych kategorii prosimy o przesłanie następujących materiałów:</p>

<p><a href="./assets/LUDZIE.docx">LUDZIE</a><br>
	<a href="./assets/ZESPOLY.docx">ZESPOŁY</a><br>
	<a href="./assets/MIEJSCA.docx">MIEJSCA</a><br>
	<a href="./assets/MEDIA.docx">MEDIA</a></p>

<p>Jednocześnie zaznaczamy, iż brak nazwiska, grupy, organizacji czy innego hasła nie oznacza, że nie jest ono w chwili obecnej opracowywane i wkrótce nie zostanie dodane do bazy.   Zależy nam na rzetelnym opracowywaniu haseł, a to niestety wymaga często czasu.</p>
	</div>
</template>

<script>

// console.log('licomponent', licomponent);

export default {
	name: 'dolacz',


	data() {
		return {
			loading: true,
			error: false,
			msg: '',
		};
	},

	computed: {

	},
	mounted() {


	},


};
</script>

<style lang="less">
.dolacz a:link {
	text-decoration: underline;
}
</style>
